import React from "react";

function Copyright() {
    return (
        <>
            <div className="text-center p-4 text-white opacity-20 text-sm">2023 Copyright - Hiç bir hakkı saklı değildir.</div>
        </>
    );
}

export default Copyright